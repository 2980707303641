export const LANGUAGE = 'es-cl'
export const WEBSITEURL = import.meta.env.VITE_BASE_URL
export const APPROVEDEMAILNAME = 'notificador'
export const APPROVEDEMAILDOMAIN = '@idcloud.cl'
export const APPROVEDEMAILDOMAINEDIT = false
export const UPDATEDEMAIL = 'notificador@idcloud.cl'
export const SITES = {
  EMPLOYEE: {name: 'personnel', home: 'employeeHome'},
  ENTERPRISE: {name: 'enterprise', home: 'companyHome'},
  APPROVER: {name: 'approver', home:'approverHome'},
  SUPERADMIN: {name: 'superadmin', home:'superadminHome'},
  GUARD: {name: 'guard', home: 'guardHome'},
  PRINTER: {name: 'printer', home: 'printerHome'},
  MACHINE: {name: 'machine', home: 'machineHome'}
}
export const PERSONNELLOGIN = `https://${WEBSITEURL}/#/${SITES.EMPLOYEE.name}/login`
export const BASEPATH = '/'
export const BATCHES = {
  PAGESIZE: 3,
  FILTER: {
    'stopped': true, 'pending': true,
    'printing': true, 'printed': true,
    'error': true, 'archived': false
  },
  POLLINGTIME: 20000,
  SHORTPOLLINGTIME: 2000
}
export const PRINTBATCHES = {
  PAGESIZE: 3,
  FILTER: {
    'pending': true, 'queued': true,
    'printing': true, 'printed': true,
    'stopped': true, 'error': true, 'archived': false
  },
  POLLINGTIME: 15000 }
 export const EVENTRECOVER = {
  POLLINGTIME: 3000
 } 
export const DATACOLORSCHEMA = {
  approved: {normal: '#46BFBD', hover: '#5AD3D1'}, // verde
  pending: {normal: '#F7464A', hover: '#FF5A5E'}, // rojo
  unanswered: {normal: '#FDB45C', hover: '#FFC870'}, // amarillo
  printed: {normal: '#4699f7', hover: '#70b3ff'}, // azul
  error: {normal: '#F7464A', hover: '#FF5A5E'}, // rojo
  cardswithError: {normal: '#F7464A', hover: '#FF5A5E'}, // rojo
  cardsprinted: {normal: '#46BFBD', hover: '#5AD3D1'}, // verde
  cardspending: {normal: '#949FB1', hover: '#A8B3C5'}, //  gris
  cardsqueued: {normal: '#FDB45C', hover: '#FFC870'}, //  amarillo
  default: {normal: '#949FB1', hover: '#A8B3C5'}, // gris
}
export const DOUGHNUTSIZE = { height: 300, width: 300 }
export const MANDATORYSYSTEMFIELDKEYS = [ 'name', 'company', 'photo' ]
export const USER = {
  MANDATORYLABELS:  [ 'photo', 'name', 'accountName', 'email', 'status'],
  MANDATORYLABELSWITHBATCH:  [ 'photo', 'name', 'accountName', 'email', 'batchName', 'status'],
  MANDATORYLABELSHID:  [ 'photo', 'name', 'accountName', 'digitalKeyStatus', 'status'],
  MANDATORYLABELSHIDWITHBATCH:  [ 'photo', 'name', 'accountName', 'digitalKeyStatus', 'batchName','status'],
  STATES: [ 'approved', 'pending', 'unanswered', 'deactivated' ],
  PAGESIZE: 20,
  NOPHOTO: '/images/default/no-photo.png',
  PHOTOSIZERATIO: 3 / 4
}
export const PASSRESTRICTIONS = {
  I18NVALUE: 'passwordRestrictions',
  CHALLENGES: ['hasRightLength', 'hasMixedCase', 'hasNumber', 'hasSpecialChar']
}
export const ACCESSLOG = {
  MANDATORYLABELS: [ 'name', 'timestamp', 'guardName'],
  PAGESIZE: 20,
}
export const PRINT = {
  LABELS: [ 'status', 'name' ],
  STATES: [ 'PENDING', 'PRINTED', 'WITH_ERROR' ],
  PAGESIZE: 20,
  POLLINGTIME: 15000
}
export const PRINTER = {
  POLLINGTIME: 30000,
  DANGERRIBBONMIN: 26 
}
export const TEMPLATE = { 
  BASESTYLE:  {
    'style': 'height: 550px; width: 350px;',
    'front': { 
      'text': [],
      'background': {
        'style': 'width: 100%; overflow: hidden;',
        'src': ''
      },
      'photo': {
        'style': 'top: 20px; left: 110px; width: 130px;',
        'src': '/images/default/dummyUserPhoto.png'
      },
      'qrProof': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/qr-proof.png'
      },
      'qrCustom': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/qr-custom.png'
      },
      'barcode': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/barcode.png'
      },
    },
    'back': { 
      'text': [],
      'background': {
        'style': 'width: 100%; overflow: hidden;',
        'src': ''
      },
      'qrProof': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/qr-proof.png'
      },
      'qrCustom': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/qr-custom.png'
      },
      'barcode': {
        'style': 'top: 440px; left: 130px; width: 100px; display: none;',
        'src': '/images/default/barcode.png'
      },
    }
  },
  DEFAULTTEXTFIELD: 'left: 0px; top: 0px; fontFamily: Arial; fontSize: 24px; '
    + 'color: #000000; fontStyle: normal; fontWeight: normal; width: 350px; textAlign:center;',
  MINQRSIZE: 100,
  PHOTORATIO: 4/3,
  PADDINGRIGHT: 2,
  PADDINGLEFT: 2,
  SAFEBORDER: 2,
  FONTSPATH: '/fonts/',
}
export const BARCODETYPES = {
  qrCustom: { SIZE: 100, DUMMYSRC: '/images/default/qr-custom.png'},
  qrProof: { SIZE: 100, DUMMYSRC: '/images/default/qr-proof.png'},
  barcode: { SIZE: 100, DUMMYSRC: '/images/default/barcode.png'}
}
export const BARCODEDEFAULTSIZE = 100

export const MODALCOLORS = {
  variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
  headerBgVariant: 'dark',
  headerTextVariant: 'light',
  bodyBgVariant: 'light',
  bodyTextVariant: 'dark',
  footerBgVariant: 'warning',
  footerTextVariant: 'dark'
}

export const IDUSERPHOTOS = {
baseURL: import.meta.env.NODE_ENV === 'production'
? ''
    : 'https://'+import.meta.env.VITE_BASE_URL +'/',
}

export const BASEFONTS = {
  LIST: [
    { value: 'Arial', text: 'Arial' },
    { value: 'Verdana', text: 'Verdana' },
    { value: 'Tahoma', text: 'Tahoma' },
    { value: 'Trebuchet MS', text: 'Trebuchet MS' },
    { value: 'Times New Roman', text: 'Times New Roman' },
    { value: 'Georgia', text: 'Georgia' },
    { value: 'Courier New', text: 'Courier New' },
  ],
  DEFAULT: 'Arial'
}