
<template>
  <div class="app-body">
    <navBar id="navbar" :expired="isExpired" />
    <div class="content">
      <!-- TODO: tal vez sea necesario agregar keep-alive -->
      <router-view />
    </div>
  </div>
</template>
<script>
import '@/assets/css/custom.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@/assets/css/main.scss'
import 'vue-toastification/dist/index.css'

import navBar from './components/Navbar.vue'

export default {
  name: 'App',
  components: { navBar },
  provide() {
    return { expire: this.expire }
  },
  data() {
    return {
      isExpired: false
    }
  },
  methods: {
    expire(e) {
      this.isExpired = e
    },
  },
}
</script>