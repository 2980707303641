<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" v-if="showNav" class="top-nav-app">
    <b-navbar-brand @click.prevent="goToHome()"><img class="logo" src="@/assets/logo.png" alt="logo"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" />
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item v-if="canShowMenu('ENTERPRISE') || canShowMenu('PRINTER')"
                    :class="[ 'home', 'navbar-item', homeDisabled ? 'disabled' : '' ]"
                    :disabled="homeDisabled"
                    @click="sendInstruction({ name: 'home', disableHome: true })">
          <b-icon-house class="w-icon" size="1.1" /> {{ $t('home') }}
        </b-nav-item>
        <template v-if="canShowMenu('ENTERPRISE') && menu" >
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'manageTemplates', disableHome: true })">
            {{ $t('manageTemplates') }}
          </b-nav-item>
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'findEmployee', disableHome: true })">
            {{ $t('findEmployee') }}
          </b-nav-item>
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'newBatch', disableHome: true })">
            {{ $t('newBatch') }}
          </b-nav-item>
        </template>

        <template v-if="canShowMenu('GUARD')" >
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'showAccessLog', disableHome: false })">
            {{ $t('showAccessLog') }}
          </b-nav-item>
        </template>

        <template v-if="canShowMenu('PRINTER') && menu" >
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'managePrinters', disableHome: true })">
            {{ $t('managePrinters') }}
          </b-nav-item>
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'manageMachineUsers', disableHome: true })">
            {{ $t('manageMachineUsers') }}
          </b-nav-item>
          <b-nav-item class="navbar-item"
                      @click="sendInstruction({ name: 'printsReport', disableHome: false })">
            {{ $t('printsReport') }}
          </b-nav-item>
        </template>

      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown v-if="(canShowMenu('ENTERPRISE') && menu) || (canShowMenu('SUPERADMIN') && menu)"
                             :text="$t('administration')" right>
          <b-dropdown-item v-if="canShowMenu('SUPERADMIN')" @click="sendInstruction({ name: 'manageUsersAdministrators', disableHome: true })">
            {{ $t('manageAdministrators') }}
          </b-dropdown-item>                   
          <b-dropdown-item v-if="enableGuardProfile && canShowMenu('SUPERADMIN')" @click="sendInstruction({ name: 'manageUsers', disableHome: true })">
            {{ $t('manageGuards') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="enableApproverProfile && (enableSystemUserManagementForAdmin || canShowMenu('SUPERADMIN'))" @click="sendInstruction({ name: 'manageUsersApprovers', disableHome: true })">
            {{ $t('manageApprovers') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canShowMenu('SUPERADMIN')" @click="sendInstruction({ name: 'managePrinterUsers', disableHome: true })">
            {{ $t('managePrinterUsers') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canShowMenu('ENTERPRISE')" @click="sendInstruction({ name: 'printsReport', disableHome: false })">
            {{ $t('printsReport') }}
          </b-dropdown-item >
          <b-dropdown-item v-if="enableHID && canShowMenu('SUPERADMIN')" @click="sendInstruction({ name: 'keyReport', disableHome: false })">
            {{ $t('keyReport') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="enableHID && canShowMenu('SUPERADMIN')" @click="sendInstruction({ name: 'eventRecover', disableHome: false })">
            {{ $t("eventRecoverHID")}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="(canShowMenu('ENTERPRISE') && menu) || (canShowMenu('SUPERADMIN') && menu)"
                             :text="$t('help')" right>
                             <!-- :text="$t('administration')" right> -->
          <b-dropdown-item @click="sendInstruction({ name: 'modalSupport', disableHome: true })">
            {{ $t('support') }}
          </b-dropdown-item>
          <b-dropdown-item @click="sendInstruction({ name: 'modalAbout', disableHome: true })">
            {{ $t('about') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="login" @click="logout()">
            {{ $t('logout') }} <b-icon-box-arrow-right class="w-icon" size="1.1" />
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { UserFunctions } from '@/services/loginService'
import { SITES } from '@/constants'

export default {
  name: 'navBar',
  props: ['expired'],
  watch: {
    expired(newVal) {
      if (newVal) {
        this.logout()
      }}
  },
  data() {
    return {
      homeDisabled: true,
      login: false,
      menu: true,
      enableApproverProfile: false,
      enableGuardProfile: false,
      enableHID: false,
      enableSystemUserManagementForAdmin: false
    }
  },
  computed: {
    showNav() { return !(this.$route.meta.guest || this.$route.meta.noMenu) }
  },
  methods: {
    getParams() {
      let siteFolder = this.$route.meta.site ? SITES[this.$route.meta.site].name : document.documentURI.match(/#\/(\w+)\//)[1]
      return this.$route.params.siteFolder ? this.$route.params : { siteFolder }
    },
    goToHome() {
      let home = SITES[this.$route.meta.site]?.home
      if (this.$route.name === home) {
        this.$router.go()
      } else {
        let params = this.getParams()
        this.$router.push({ name: home, params })
      }
    },
    logout() {
      let params = this.getParams()
      UserFunctions.logout()
      this.login = false
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login', params })
      }
    },
    canShowMenu(category) {
      return this.$route.meta.site === category
    },
    hideMenuOptions(payload) {
      this.homeDisabled = payload
      this.menu = payload
    },
    sendInstruction({name, disableHome}) {
      this.homeDisabled = disableHome
      if (name === 'home') {
        this.menu = true
      }
      this.emitter.emit('menu-opt', name)
    }
  },
  mounted() {
    this.login = UserFunctions.isAuthenticated()
  },
  created() {
    // this.emailLikeID = import.meta.env.VITE_ENABLE_EMAIL_LIKE_ID === 'true'
    this.enableApproverProfile = import.meta.env.VITE_ENABLE_APPROVER_PROFILE === 'true'
    this.enableGuardProfile = import.meta.env.VITE_ENABLE_GUARD_PROFILE === 'true'
    this.enableHID = import.meta.env.VITE_ENABLE_HID === 'true'
    this.enableSystemUserManagementForAdmin = import.meta.env.VITE_ENABLE_SYSTEM_USER_MANAGEMENT_FOR_ADMIN === 'true'
    console.log(this.enableSystemUserManagementForAdmin)
    this.emitter.on('send-login', () => {
      this.login = UserFunctions.isAuthenticated()
    })
    this.emitter.on('menu-show', (payload) => {
      this.hideMenuOptions(payload)
    })
  }
}
</script>